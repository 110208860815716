import { Box } from "@chakra-ui/layout";
import { HiArrowRight } from "react-icons/hi";

export const ArrowLink = ({ children, color }: any) => {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      fontWeight={600}
      color={`${color}.600`}
      cursor="pointer"
    >
      {children}
      <Box ml={1} pt={1}>
        <HiArrowRight/>
      </Box>
    </Box>
  );
};

import { Heading, Box, Text } from "@chakra-ui/layout";

export const PageSectionHeading = ({
  children,
  textAlign = "left",
  mb,
}: any) => {
  return (
    <Heading
      textAlign={textAlign}
      mb={mb}
      fontWeight={700}
      fontSize={{ base: "3xl", lg: "3xl" }}
    >
      {children}
    </Heading>
  );
};

export const PageSectionHeadingLarge = ({
  children,
  textAlign = "left",
  mb,
}: any) => {
  return (
    <Heading
      textAlign={textAlign}
      mb={mb}
      fontWeight={700}
      fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
    >
      {children}
    </Heading>
  );
};

export const PageSection = ({
  pt = { base: 16, lg: 32 },
  pb = { base: 16, lg: 32 },
  bg = "transparent",
  children,
  mb,
  border = true
}: any) => {
  return (
    <Box
      pt={pt}
      pb={pb}
      bg={bg}
      borderTop={border && "1px solid"}
      borderColor="gray.150"
      mb={mb}
    >
      {children}
    </Box>
  );
};

export const PageSectionPreheading = ({ children }: any) => {
  return (
    <Text
      textTransform="uppercase"
      letterSpacing="widest"
      fontWeight={500}
      textAlign="center"
      color="primary.500"
      mb={2}
    >
      {children}
    </Text>
  );
};

export const PageSectionSubheading = ({ children, mb }: any) => {
  return (
    <Text
      fontSize="xl"
      mx="auto"
      maxW="2xl"
      textAlign="center"
      mb={mb}
      color="gray.700"
    >
      {children}
    </Text>
  );
};

import { Avatar, Box, Flex } from "@chakra-ui/react";
import slugify from "slugify";
import { StaticTestimonial } from "types/facebook";

interface IProductTestimonialStaticCard {
  testimonial: StaticTestimonial;
}

export const ProductTestimonialStaticCard: React.FC<
  IProductTestimonialStaticCard
> = ({ testimonial }) => {
  const { name, company, quote, jobTitle, avatar } = testimonial;
  return (
    <Box>
      <Box
        id={`testimonial-video-${slugify(name, {
          lower: true,
        })}`}
        bg="gray.100"
        rounded="lg"
        overflow="hidden"
        p={8}
        width="100%"
        height="100%"
        textAlign="center"
        display="inline-block"
        mb={6}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Avatar size="2xl" name={name} src={avatar} bg={"white"} />

          <Flex
            p={6}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box fontSize="xl" mb={4} maxW="lg" lineHeight="1.4">
              {quote}
            </Box>
            <Box>
              <Box fontSize="md" fontWeight={600} lineHeight="none" mb={2}>
                {name}
              </Box>
              <Box color="gray.700" lineHeight="none" fontSize="sm">
                {jobTitle}, {company}
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

import { Box, Text, Heading, SimpleGrid } from "@chakra-ui/react";
import { Button, Container } from "components";
import { InternalLink } from "components/atoms/InternalLink";
import { NavigationPageSpacer } from "components";
import { EXTERNAL_LINKS, route } from "config/routes";
import { HomepageVideo } from "./HomepageVideo";
import { PressBanner } from "components/molecules/PressBanner";
import { SITE_DESCRIPTION } from "config/seo";

const InlineBulletText = ({ children }) => {
  return <Box whiteSpace="nowrap">{children}</Box>;
};

const InlineBullet = () => {
  return <Box minW={1} minH={1} bg="gray.600" rounded="full" mx={3} />;
};

export const HomepageMasthead = () => {
  return (
    <>
      <NavigationPageSpacer />
      <Box pt={24} pb={24}>
        <Container>
          <Box textAlign={{ base: "left", md: "center" }}>
            <Heading
              fontSize={{ base: "4xl", lg: "6xl" }}
              mb={4}
              fontWeight={800}
              lineHeight="1.2"
              maxW="3xl"
              mx="auto"
            >
              A fully integrated lead generation and marketing suite
            </Heading>
            <Text
              fontSize={{ base: "xl", lg: "xl" }}
              color="gray.700"
              maxW="xl"
              mx="auto"
              mb={6}
            >
              {SITE_DESCRIPTION}
            </Text>
            <Box mb={12} maxW="lg" mx="auto">
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 2, md: 6 }}
                mb={6}
              >
                <Box>
                  <Box as="a" href={EXTERNAL_LINKS.SIGNUP_UP.href}>
                    <Button size="lg" isBlock>
                      Get started
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <InternalLink href={route("DEMO_INDEX")}>
                    <Button size="lg" color="default" isBlock>
                      Book a demo
                    </Button>
                  </InternalLink>
                </Box>
              </SimpleGrid>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="gray.700"
              >
                <InlineBulletText>Same day set-up</InlineBulletText>

                <InlineBullet />
                <InlineBulletText>Training provided</InlineBulletText>
              </Box>
            </Box>
            <Box mt={12} position="relative">
              <PressBanner />
            </Box>
          </Box>
          <HomepageVideo />
        </Container>
      </Box>
    </>
  );
};

// <Box
// width="50%"
// right={0}
// overflow="hidden"
// display={{ base: "none", lg: "block" }}
// >
// <Box mr={-3}>
//   <Image src="/images/screenshots/case-study-1.png" />
// </Box>
// </Box>

// <Box pt={12} pb={24}>
//         <Box display="flex" flexDirection={{ base: "column", md: "row" }}>
//           <Box
//             width={{ base: "100%", lg: "50%" }}
//             justifyContent="flex-end"
//             display="flex"
//           >
//             <Box
//               maxW={{ lg: MAX_SITE_WIDTH / 2 }}
//               pl={4}
//               pr={{ base: 6, lg: 16 }}
//               display="flex"
//               alignItems="center"
//             >
//               <Box>
//                 <Heading
//                   fontSize={{ base: "4xl", md: "5xl" }}
//                   mb={4}
//                   fontWeight={800}
//                   lineHeight="1.2"
//                   // maxW="lg"
//                 >
//                   A fully integrated lead generation and marketing suite.
//                 </Heading>
//                 <Text fontSize={{ base: "xl", lg: "2xl" }} color="gray.700">
//                   Estate agents across the UK are using LeadPro to generate and
//                   manage leads.
//                 </Text>
//                 <Box borderTop="1px solid" borderColor="gray.200" my={5} />
//                 <Stack mb={5} spacing={4}>
//                   <Checkpoint label="Same day set-up and training provided" />
//                   <Checkpoint label="Monthly rolling contract, no lock-in" />
//                   <Checkpoint label="No lengthy installations or additional software required" />
//                 </Stack>
//                 <Box mb={4}>
//                   <SimpleGrid
//                     columns={{ base: 1, md: 2 }}
//                     spacing={{ base: 2, md: 6 }}
//                   >
//                     <Box>
//                       <Box as="a" href={EXTERNAL_LINKS.SIGNUP_UP.href}>
//                         <Button size="lg" isBlock>
//                           Get started
//                         </Button>
//                       </Box>
//                     </Box>
//                     <Box>
//                       <InternalLink href={route("DEMO_INDEX")}>
//                         <Button size="lg" color="default" isBlock>
//                           Book a demo
//                         </Button>
//                       </InternalLink>
//                     </Box>
//                   </SimpleGrid>
//                 </Box>
//               </Box>
//             </Box>
//           </Box>

import {
  PageSection,
  PageSectionHeadingLarge,
  PageSectionSubheading,
} from "components/molecules/PageSection";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Container } from "components/atoms/Container";
import React, { Component } from "react";
import Slider from "react-slick";
import { PressBanner } from "components/molecules/PressBanner";
import { VideoTestimonial } from "components/molecules/VideoTestimonial";
import { CSSColumns } from "components/atoms/CSSColumns";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
    return (
      <div>
        <h2> Single Item</h2>
        <Slider {...settings}>
          <div>
            <h3>1</h3>
          </div>
          <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
          <div>
            <h3>4</h3>
          </div>
          <div>
            <h3>5</h3>
          </div>
          <div>
            <h3>6</h3>
          </div>
        </Slider>
      </div>
    );
  }
}

export const HomepageTestimonial = ({ testimonials }) => {
  return (
    <PageSection bg="gray.50">
      <Container>
        <Box mb={{ base: 6, lg: 12 }}>
          <PageSectionHeadingLarge textAlign="center" mb={6}>
            What our customers say
          </PageSectionHeadingLarge>
          <PageSectionSubheading>
            These agents are already winning more instructions using LeadPro!
            Plus many more agency brands, totalling to over 500 branches
          </PageSectionSubheading>
          <PressBanner showTitle={false} />
        </Box>
        <CSSColumns
          columnCount={{ base: 1, lg: 2 }}
          columnGap={{ base: 0, lg: 32 }}
          maxW="5xl"
          mx="auto"
        >
          {testimonials.map((testimonial, i) => {
            return <VideoTestimonial testimonial={testimonial} key={i} />;
          })}
        </CSSColumns>
      </Container>
    </PageSection>
  );
};

import { Box, BoxProps } from "@chakra-ui/layout";
import React from "react";

interface BreakpointValue {
  base?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

interface T {
  children: React.ReactNode;
  columnCount: any;
  columnGap: any;
}

export const CSSColumns = ({
  columnCount,
  columnGap,
  children,
  ...rest
}: BoxProps & T) => {
  return (
    <Box {...rest} sx={{ columnGap, columnCount }}>
      {children}
    </Box>
  );
};

import { Box, AspectRatio, Flex } from "@chakra-ui/react";
import React from "react";
import { ITestimonial } from "interfaces/testimonial.interface";
import { getContentfulImageUrl } from "utils/getContentulImageUrl";
import { useDisclosure } from "@chakra-ui/hooks";
import { VideoPlayButton } from "components/atoms/VideoPlayButton";
import { IContentfulImage } from "interfaces/contentful-image.interface";
import { VideoModal } from "./VideoModal";
import { NextImage } from "components";
import slugify from "slugify";

const TestimonialVideoThumb = ({ cover }: { cover: IContentfulImage }) => {
  return (
    <>
      <AspectRatio
        ratio={16 / 9}
        bg="gray.200"
        cursor="pointer"
        rounded="lg"
        overflow="hidden"
      >
        <Box position="relative">
          <Box w={16} h={16} position="absolute" zIndex={3}>
            <VideoPlayButton />
          </Box>
          <NextImage alt={'an image for an article'} src={getContentfulImageUrl(cover)} fill />
        </Box>
      </AspectRatio>
    </>
  );
};

export const VideoTestimonial = ({
  testimonial,
}: {
  testimonial: ITestimonial;
}) => {
  const { name, quote, jobTitle, company, avatar, cover, video } = testimonial;

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VideoModal
        src={getContentfulImageUrl(video)}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Box
        id={`testimonial-video-${slugify(name, {
          lower: true,
        })}`}
        bg="gray.100"
        rounded="lg"
        overflow="hidden"
        p={8}
        width="100%"
        textAlign="center"
        display="inline-block"
        mb={6}
        cursor="pointer"
        transition="all .25s"
        _hover={{
          transform: "translateY(-10px)",
          backgroundColor: "white",
          shadow: "lg",
        }}
        onClick={onOpen}
      >
        <Box flex={1} mb={{ base: 6, lg: 0 }}>
          <TestimonialVideoThumb cover={cover} />
        </Box>

        <Flex
          p={6}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box fontSize="2xl" mb={4} maxW="lg" lineHeight="1.4">
            {quote}
          </Box>
          <Box>
            <Box fontSize="md" fontWeight={600} lineHeight="none" mb={2}>
              {name}
            </Box>
            <Box color="gray.700" lineHeight="none" fontSize="sm">
              {jobTitle}, {company}
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

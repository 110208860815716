import {
  PageSection,
  PageSectionSubheading,
  
  PageSectionHeadingLarge,
} from "components/molecules/PageSection";
import { Container } from "components";
import { Box, SimpleGrid, AspectRatio, Image } from "@chakra-ui/react";
import { InternalLink } from "components/atoms/InternalLink";
import { route } from "config/routes";
import { ArrowLink } from "components/atoms/ArrowLink";
import { IIntegration } from "interfaces/integration.interface";
import { getContentfulImageUrl } from "utils/getContentulImageUrl";

export const HomepageIntegrations = ({
  integrations,
}: {
  integrations: IIntegration[];
}) => {
  return (
    <PageSection border="none">
      <Container>
        <PageSectionHeadingLarge textAlign="center" mb={4}>
          Works with your existing tools
        </PageSectionHeadingLarge>
        <PageSectionSubheading mb={8}>
          LeadPro integrates with most Estate Agent CRMs, and the majority of
          other software through our Zapier integration.{" "}
        </PageSectionSubheading>
        <Box maxW="6xl" mx="auto">
          <SimpleGrid columns={{ base: 2, lg: 4 }} spacing={4} mb={6}>
            {integrations.map((integration: IIntegration, i: number) => {
              return (
                <AspectRatio
                  key={i}
                  ratio={16 / 9}
                  rounded="md"
                  border="1px solid"
                  borderColor="gray.200"
                  overflow="hidden"
                >
                  <Image src={getContentfulImageUrl(integration.logo)} />
                </AspectRatio>
              );
            })}
          </SimpleGrid>
          <Box textAlign="center">
            <InternalLink href={route("INTEGRATIONS_INDEX")}>
              <ArrowLink color="primary">See all integrations</ArrowLink>
            </InternalLink>
          </Box>
        </Box>
      </Container>
    </PageSection>
  );
};

import { Box } from "@chakra-ui/react";
import { VideoIcon } from "./VideoIcon";

export const VideoPlayButton = () => {
  return (
    <Box
      w="100%"
      h="100%"
      rounded="full"
      boxShadow="0 0 0 6px rgba(00,00,00,0.1)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      transition="all .25s"
      bg="blue.500"
      bgGradient="linear(to-r, blue.500, primary.500)"
      cursor="pointer"
      color="white"
      _hover={{
        boxShadow: "0 0 0 12px rgba(00,00,00,0.1)",
      }}
    >
      <Box w="35%" mr={-1}>
        <VideoIcon />
      </Box>
    </Box>
  );
};

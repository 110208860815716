import { Box, Heading, Stack } from "@chakra-ui/react";
import { ArrowLink } from "components/atoms/ArrowLink";
import { Checkpoint } from "components/atoms/Checkpoint";
import { InternalLink } from "components/atoms/InternalLink";
import { IProduct } from "interfaces/product.interface";
import React from "react";

export const SolutionBlock = ({
  isInversed,
  product,
  children,
}: {
  isInversed?: boolean;
  product: IProduct;
  children: React.ReactNode;
}) => {
  const { name, summary, color, slug, keyBenefits } = product;

  return (
    <Box
      display="flex"
      width="100%"
      mx="auto"
      flexDirection={{ base: "column", lg: "row" }}
      alignItems="center"
      bg="gray.100"
      rounded="lg"
    >
      <Box
        width={{ base: "100%", lg: "50%" }}
        order={isInversed && 2}
        p={{ base: 8, lg: 16 }}
        pb={0}
      >
        <Box display="flex" mb={3} alignItems="center">
          {/* <Box mr={3}>
            <ProductIcon color={color} icon={icon} />
          </Box> */}
          <Box fontSize="lg" fontWeight={600} color={`${color}.600`}>
            {name}
          </Box>
        </Box>
        <Heading mb={4} fontSize={{ base: "3xl", lg: "3xl" }} fontWeight={600}>
          {summary}
        </Heading>
        <Box mb={6}>
          <Stack spacing={4}>
            {keyBenefits &&
              keyBenefits.map((point) => {
                return <Checkpoint key={point} label={point} color={color} />;
              })}
          </Stack>
        </Box>
        <Box mb={6}>
          <InternalLink href={slug}>
            <ArrowLink color={color}>Explore {name}</ArrowLink>
          </InternalLink>
        </Box>
      </Box>
      {children}
    </Box>
  );
};

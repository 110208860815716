import { PageSectionHeadingLarge } from "components/molecules/PageSection";
import { Container, Checkpoint, Button } from "components";
import { Box } from "@chakra-ui/layout";
import { Stack, Image } from "@chakra-ui/react";
import { EXTERNAL_LINKS } from "config/routes";

export const HomepageCTA = () => {
  return (
    <Box>
      <Container>
        <Box
          bg="primary.100"
          zIndex={3}
          pos="relative"
          rounded="lg"
          overflow="hidden"
          alignItems="center"
          display="flex"
        >
          <Box flex={1} p={12}>
            <PageSectionHeadingLarge mb={4}>
              Ready to generate more leads?
            </PageSectionHeadingLarge>
            <Stack spacing={4} mb={6}>
              <Checkpoint label="Same day set-up and training provided" />
              <Checkpoint label="No lengthy installations or additional software required" />
            </Stack>
            <Box as="a" href={EXTERNAL_LINKS.SIGNUP_UP.href}>
              <Button size="lg" isWide>
                Get started
              </Button>
            </Box>
          </Box>
          <Box flex={1} display={{ base: "none", lg: "block" }}>
            <Box
              shadow="sm"
              border="1px solid"
              right={-6}
              bottom={-6}
              position="relative"
              rounded="md"
              overflow="hidden"
              borderColor="gray.150"
            >
              <Image src="/images/screenshots/1.png" />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

import {
  PageSection,
  PageSectionHeadingLarge,
} from "components/molecules/PageSection";
import { Container } from "components";
import { Box } from "@chakra-ui/layout";
import { SolutionBlock } from "./HomepageSolutionBlock";
import { Stack, Image, AspectRatio } from "@chakra-ui/react";
import { useAppContext } from "pages/_app";

export const HomepageSolutions = () => {
  const {
    products: {
      IVT,
      LeadResponder,
      Autocaller,
      FacebookAdvertising,
      Dashboard,
    },
  } = useAppContext();

  return (
    <PageSection border={false} pt={0}>
      <Box maxW="3xl" mx="auto" mb={{ base: 6, lg: 16 }}>
        <PageSectionHeadingLarge textAlign="center">
          Our product suite
        </PageSectionHeadingLarge>
      </Box>
      <Stack spacing={{ base: 12, lg: 12 }}>
        <SolutionBlock product={IVT}>
          <Box
            width={{ base: "100%", lg: "50%" }}
            justifyContent="center"
            p={{ base: 8, lg: 12 }}
            pb={0}
          >
            <Image
              src={`/images/illustrations/1.png`}
              maxW={{ lg: "sm" }}
              mx="auto"
              mb={{ base: -2, lg: -16 }}
              shadow="lg"
              rounded="lg"
            />
          </Box>
        </SolutionBlock>
        <SolutionBlock product={LeadResponder}>
          <Box
            width={{ base: "100%", lg: "50%" }}
            justifyContent="center"
            p={{ base: 8, lg: 12 }}
          >
            <Image src={`/images/illustrations/2.png`} />
          </Box>
        </SolutionBlock>
        <SolutionBlock product={Autocaller}>
          <Box
            width={{ base: "100%", lg: "50%" }}
            justifyContent="center"
            p={{ base: 8, lg: 12 }}
          >
            <Image src={`/images/illustrations/3.png`} position="relative" />
          </Box>
        </SolutionBlock>
        <SolutionBlock product={FacebookAdvertising}>
          <Box
            width={{ base: "100%", lg: "50%" }}
            justifyContent="center"
            p={{ base: 8, lg: 12 }}
          >
            <Image
              src={`/images/screenshots/FacebookMattRabbittsCover.png`}
              position="relative"
            />
          </Box>
        </SolutionBlock>
        <SolutionBlock product={Dashboard}>
          <Box
            width={{ base: "100%", lg: "50%" }}
            justifyContent="center"
            p={{ base: 8, lg: 12 }}
          >
            <Image
              src={`/images/screenshots/DashboardCoverImage.png`}
              position="relative"
            />
          </Box>
        </SolutionBlock>
      </Stack>
    </PageSection>
  );
};

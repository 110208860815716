import { Homepage } from "components/pages/Homepage";
import { ITestimonial } from "interfaces/testimonial.interface";
import { IIntegration } from "interfaces/integration.interface";
import { fetchIntegrations, fetchTestimonials } from "services/contentful";

export default function Home({
  integrations,
  testimonials,
}: {
  integrations: IIntegration[];
  testimonials: ITestimonial[];
}) {
  return <Homepage integrations={integrations} testimonials={testimonials} />;
}

export async function getServerSideProps() {
  const { data } = await fetchIntegrations();
  const { data: testimonials } = await fetchTestimonials();

  return {
    props: {
      testimonials,
      integrations: data,
    },
  };
}

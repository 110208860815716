import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import ScrollLock from "react-scrolllock";
import { AspectRatio } from "@chakra-ui/layout";
import ReactPlayer from "react-player";
import { HiX } from "react-icons/hi";

export const VideoModal = ({
  isOpen,
  onClose,
  src,
}: {
  isOpen: boolean;
  onClose(): void;
  src: string;
}) => {
  const [isPlaying, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setPlaying(true);
    }
  }, [isOpen]);

  const variants = {
    hidden: { scale: 0.99, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={variants}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.05 }}
          animate="visible"
          exit="hidden"
          style={{
            position: "fixed",
            height: "100vh",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: "rgba(00,00,00,0.2)",
          }}
        >
          <Box
            alignItems="Center"
            justifyContent="center"
            fontSize="xl"
            cursor="pointer"
            display="inline-flex"
            lineHeight="none"
            ml="auto"
            top={4}
            right={4}
            w={10}
            h={10}
            position="absolute"
            color="gray.900"
            onClick={onClose}
            bg="white"
            rounded="full"
          >
            <HiX />
          </Box>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onClose}
          >
            <ScrollLock isActive={isOpen} />
            <Box w="90%" onClick={(e) => e.stopPropagation()}>
              <AspectRatio ratio={16 / 9} rounded="md" shadow="lg" width="100%">
                <ReactPlayer
                  url={src}
                  width="100%"
                  playing={isPlaying}
                  height="100%"
                  controls
                />
              </AspectRatio>
            </Box>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

import { AspectRatio, Box } from "@chakra-ui/react";
import { NextImage } from "components";
import { Container } from "components";
import { useMemo } from "react";

export const PressBannerItem = ({ logo }: { logo: string }) => {
  return (
    <Box width={{ base: "33.33%", lg: "16.66%" }}>
      <AspectRatio ratio={16 / 9}>
        <NextImage alt={'this is a company logo'} fill src={`/images/logos/clients/${logo}.png`} />
      </AspectRatio>
    </Box>
  );
};

const logos = [
  "yourmove",
  "carter-jonas",
  "reeds-rains",
  "winkworth",
  "northwood",
  "martinandco",
];

export const PressBanner = ({
  showTitle = true,
  customLogos,
}: {
  showTitle?: boolean;
  customLogos?: string[];
}) => {
  const companyLogos = useMemo(() => {
    if (customLogos) {
      return customLogos;
    } else {
      return logos;
    }
  }, [customLogos, logos]);

  return (
    <Box pt={12} pb={6}>
      {showTitle && (
        <Box
          textTransform="uppercase"
          mb={0}
          color="gray.500"
          fontSize="sm"
          letterSpacing="widest"
          textAlign="center"
        >
          Used by hundreds of agents around the UK
        </Box>
      )}
      <Container>
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          {companyLogos.map((logo: string, index: number) => {
            return <PressBannerItem logo={logo} key={index} />;
          })}
        </Box>
      </Container>
    </Box>
  );
};

import {
  Box,
  AspectRatio,
  Image,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { VideoIcon } from "components/atoms/VideoIcon";
import { VideoModal } from "components/molecules/VideoModal";
import { VideoPlayButton } from "components/atoms/VideoPlayButton";

const BrowserButton = ({ color }) => {
  return (
    <Box
      w={{ base: 1, md: 2 }}
      h={{ base: 1, md: 2 }}
      bg={`${color}.600`}
      rounded="full"
    />
  );
};

export const BrowserBar = () => {
  return (
    <Box
      bg="white"
      h={{ base: 6, md: 9 }}
      borderBottom="1px solid"
      borderColor="gray.150"
      display="flex"
      alignItems="center"
      px={4}
    >
      <HStack>
        <BrowserButton color="blue" />
        <BrowserButton color="primary" />
        <BrowserButton color="purple" />
      </HStack>
    </Box>
  );
};

export const HomepageVideo = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [isPlaying, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setPlaying(true);
    }
  }, [isOpen]);

  const variants = {
    hidden: { scale: 0.99, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  return (
    <>
      <Box position="relative" onClick={onOpen} cursor="pointer">
        <Box
          h={{ base: 300, lg: 650 }}
          overflow="hidden"
          border="1px solid"
          roundedTop="md"
          borderColor="gray.150"
        >
          <BrowserBar />
          <Image src="/images/screenshots/1.png" />
        </Box>
        <Box
          position="absolute"
          height="200px"
          bottom={0}
          width="100%"
          bgGradient="linear(to-b, transparent, gray.50)"
        ></Box>
        <Box
          w={24}
          h={24}
          
          rounded="full"
          position="absolute"
          top="50%"
          marginLeft={-12}
          marginTop={-12}
          left="50%"
        >
          <VideoPlayButton />
        </Box>
      </Box>
      <VideoModal isOpen={isOpen} src="/video.mp4" onClose={onClose} />
    </>
  );
};

import { HomepageMasthead } from "./components/HomepageMasthead";
import { Container } from "components";
import { HomepageSolutions } from "./components/HomepageSolutions";
import { HomepageIntegrations } from "./components/HomepageIntegrations";
import { HomepageTestimonial } from "./components/HomepageTestimonials";
import { IIntegration } from "interfaces/integration.interface";
import { HomepageCTA } from "./components/HomepageCTA";
import { ITestimonial } from "interfaces/testimonial.interface";
import { ProductTestimonialGrid } from "components/molecules/testimonials/ProductTestimonialGrid";
import { FACEBOOK_TESTIMONIALS } from "constants/facebook-advertising";

export const Homepage = ({
  integrations,
  testimonials,
}: {
  integrations: IIntegration[];
  testimonials: ITestimonial[];
}) => {
  return (
    <>
      <HomepageMasthead />
      <Container>
        <HomepageSolutions />
        <HomepageIntegrations integrations={integrations} />
        <HomepageTestimonial testimonials={testimonials} />
        <ProductTestimonialGrid testimonials={FACEBOOK_TESTIMONIALS} />
        <HomepageCTA />
      </Container>
    </>
  );
};

import { SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { StaticTestimonial } from "types/facebook";
import { ProductTestimonialStaticCard } from "./ProductTestimonialStaticCard";

interface IProductTestimonialGrid {
  testimonials: StaticTestimonial[];
}

export const ProductTestimonialGrid: React.FC<IProductTestimonialGrid> = ({
  testimonials,
}) => {
  return (
    <SimpleGrid
      columns={{ base: 1, lg: 3 }}
      spacing={10}
      mb={{ base: 6, lg: 12 }}
    >
      {testimonials.map((testimonial, index) => {
        return (
          <ProductTestimonialStaticCard key={index} testimonial={testimonial} />
        );
      })}
    </SimpleGrid>
  );
};

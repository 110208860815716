import { IContentfulImage } from "interfaces/contentful-image.interface";

export const getContentfulImageUrl = (obj: IContentfulImage, opts?: any) => {
  if (obj && obj.file) {
    let url = obj.file.url;

    if (opts) if (opts.width) url += "?w=500";

    return "https:" + url;
  }

  return null;
};

import { StaticTestimonial } from "types/facebook";

export const CUSTOM_LEAD_MAGNET_BULLETS: string[] = [
  "Which call to actions work the best for property ads?",
  "What is the minimum daily ad budget to get results?",
  "How best to manage leads once they are generated?",
];

export const FACEBOOK_DOWNLOAD_PATH = "/facebook-pdf-download";

export const FACEBOOK_PIE_API_ENDPOINT = "/facebook-advertising-pie";

export const FACEBOOK_TESTIMONIALS: StaticTestimonial[] = [
  {
    name: "Geoff Flewers",
    quote:
      "We are very happy with the results from Facebook Advertising through LeadPro. Of course it’s not a quick fix and will take some time to start ‘bearing fruits’ but so far we’ve booked 8 very good valuations in 8 weeks!",
    company: "Town and Country Harlow",
    jobTitle: "Senior Partner",
    avatar: "images/testimonials/GeoffFlewersTownAndCountry.jpg",
  },
  {
    name: "Sharon Wiles",
    quote:
      "Using LeadPro’s Facebook advertising enabled me to reach new potential vendors without spending hours hand delivering leaflets!",
    company: "Bespoke Estate Agent Andover",
    jobTitle: "Manager",
    avatar: "/images/testimonials/SharonWilesAvatar.jpg",
  },
  {
    name: "Paul Rai",
    quote:
      "My experience with LeadPro has been remarkable. In the past I have had such mixed results with social media. Thanks to LeadPro it has become my primary source of new business.",
    company: "EweMove Estate Agent Gillingham & Hempstead",
    jobTitle: "Owner",
    avatar: "/images/testimonials/PaulRaiTestimonial.png",
  },
];
